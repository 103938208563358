.profile-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.profile-section {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-section h3 {
  color: #6200EA;
  margin-bottom: 10px;
}

.case-summary {
  display: flex;
  justify-content: space-between;
}

.case-item {
  text-align: center;
  background: #f1f1f1;
  padding: 10px;
  border-radius: 6px;
  width: 30%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.case-item h4 {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.case-item p {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0 0;
  color: #6200EA;
}

.bank-details-form label {
  display: block;
  margin-bottom: 10px;
}

.bank-details-form input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.save-button {
  background-color: #6200EA;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.save-button:hover {
  background-color: #4a00d1;
}
