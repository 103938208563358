
  
  .questionnaire h2 {
    margin-bottom: 20px; /* Space after the heading */
  }
  
  .petition-output {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .petition-output h3 {
    margin-bottom: 10px;
    color: #333;
  }