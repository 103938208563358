/* Global Styles */
*,
*::before,
*::after {
    box-sizing: border-box; /* Include padding and border in width and height */
    margin: 0;              /* Reset default margins */
    padding: 0;             /* Reset default padding */
}

body, html {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

/* General Styles */
.form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px; /* Add padding here */
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center input fields */
}

.input-field {
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease;
    width: 100%;
    max-width: 350px;
}

.input-prefix {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 350px;
}

.input-prefix span {
    background-color: #f1f1f1;
    padding: 12px;
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 5px 0 0 5px;
    font-size: 1rem;
}

.input-prefix .input-field {
    flex-grow: 1;
    border-radius: 0 5px 5px 0;
}

.input-field:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

/* Buttons */
.submit-button {
    width: 100%;
    max-width: 350px;
    padding: 12px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto; /* Center the button */
}

.submit-button:hover {
    background-color: #45a049;
}

/* Header */
.constant-header {
    text-align: center;
    padding: 20px;
    background-color: #4CAF50;
    color: white;
}

.constant-header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.constant-header p {
    font-size: 1.2rem;
    margin-top: 0;
}

/* Footer */
.form-footer {
    text-align: center;
    padding: 20px;
    background-color: #333;
}

.form-footer p {
    font-size: 1rem;
    margin: 0;
    color: white;
}

.chamber-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    display: block;
    margin: 0 auto;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .form-content {
        padding: 15px;
        max-width: 100%; /* Ensure form doesn't exceed viewport width */
        gap: 15px;
    }

    .chamber-image {
        max-width: 100%; /* Ensure image scales within viewport */
    }

    .input-field,
    .input-prefix,
    .submit-button {
        max-width: 100%; /* Remove fixed max-widths */
    }

    .input-prefix,
    .input-group {
        width: 100%;
    }

    .input-field,
    .input-prefix .input-field,
    .input-prefix span {
        font-size: 0.9rem;
        padding: 10px;
    }

    .submit-button {
        padding: 10px;
        font-size: 0.9rem;
    }

    /* Adjust header size and padding for mobile */
    .constant-header {
        padding: 10px;
    }

    .constant-header h1 {
        font-size: 1.5rem;
    }

    .constant-header p {
        font-size: 1rem;
    }

    /* Adjust footer size for mobile */
    .form-footer {
        padding: 10px;
    }

    .form-footer p {
        font-size: 0.9rem;
    }
}
