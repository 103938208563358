html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure no extra width is added */
}


/* General Styling */
h3, h4 {
  color: #333;
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

strong {
  font-weight: bold;
}

.table-container {
  width: 100%; /* Make it responsive */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* Billing Section */
.billing-section {
  margin-top: 30px;
}

.billing-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.billing-table th, .billing-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.billing-table th {
  background-color: #f9f9f9;
  font-weight: bold;
}

.billing-table tr:hover {
  background-color: #f1f1f1;
}
/* Enhanced Modal Styling */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1000;
  width: 40%;
  max-height: 60vh;
  overflow-y: auto;
  border-radius: 12px;
  border: 1px solid #d3d3d3; /* Light grey border */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: all 0.3s ease-in-out;
}

.modal-content {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

/* Button styles inside modal */
.modal-content button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: #6200ea;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modal-content button:hover {
  background-color: #4a00c8; /* Darken button on hover */
}

/* Input and text area styles */
.modal input, .modal textarea {
  width: 100%;
  max-width: 90%; /* Limit max-width to prevent it from extending fully */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9; /* Subtle background color */
}

/* Modal appearance on hover */
.modal:hover {
  border-color: #b0b0b0; /* Slightly darker grey on hover */
}


/* Buttons */
button {
  padding: 10px 15px;
  background-color: #6200EA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #4a00c8;
}

/* Notes Section */
.notes-section ul {
  list-style-type: none;
  padding: 0;
}

.notes-section li {
  margin-bottom: 10px;
}

/* To-Do Section */
.todos-section ul {
  list-style-type: none;
  padding: 0;
}

.todos-section li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* File Upload Section */
.file-upload-section, .uploaded-files-section {
  margin-top: 40px;
}

/* Expandable Sections */
.expandable-section {
  background-color: #f7f7f7;
  padding: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.expandable-section:hover {
  background-color: #e0e0e0;
}

.expandable-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

/* Table Styling for History and Orders */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table th, table td {
  padding: 10px;
  border: 1px solid #ddd;
}

table th {
  background-color: #f9f9f9;
}

.card {
  background: linear-gradient(135deg, #ffffff, #dce2e9); /* Soft blue to silver gradient */
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}


/* Card title */
.card h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

/* Mobile-specific modal adjustments */
@media screen and (max-width: 768px) {
  .modal {
    width: 90%; /* Make the modal take most of the screen width */
    max-height: 80vh; /* Utilize more vertical space on mobile */
  }

  .modal-content {
    padding: 15px; /* Reduce padding on mobile for better fit */
  }

  .modal-content button {
    width: 100%; /* Make buttons full width on mobile for easier tapping */
    margin-bottom: 15px; /* Add more space between buttons */
  }

  .table-container {
    overflow-x: auto; /* Allow horizontal scrolling for wide tables */
    -webkit-overflow-scrolling: touch;
  }

  .billing-table th, .billing-table td {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 8px; /* Reduce padding on mobile */
}

.billing-section h4 {
  font-size: 1.2em; /* Reduce heading size on smaller screens */
}

.table-container {
  padding: 10px; /* Add some breathing room around the table */
}
}