/* General calendar styles */
.react-calendar {
    width: 100%;
    max-width: 1000px; /* Increase calendar size */
    background-color: #fff;
    border: none;
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }
  
  .react-calendar__tile {
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    min-height: 120px; /* Increase height of calendar tiles */
    border: 1px solid #ddd; /* Add border to each tile */
    position: relative; /* Ensure correct positioning for case titles */
  }
  
  .react-calendar__tile:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
  }
  
  .react-calendar__tile--active {
    background-color: #4CAF50 !important;
    color: black;
    font-weight: bold;
    border: 2px solid #45a049; /* Highlight active date */
  }
  
  .react-calendar__tile--now {
    background-color: #3f51b5;
    color: black;
    border: 2px solid #3f51b5;
  }
  
  .calendar-tile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .case-list-tile {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0 0;
    font-size: 0.8em; /* Smaller font for case titles */
    text-align: center; /* Center the case names */
    color: #333;
  }
  
  .case-list-tile li {
    margin: 2px 0;
  }
  
  .dot {
    height: 8px;
    width: 8px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 5px;
  }
  
  /* Increase the size of navigation buttons */
  .react-calendar__navigation button {
    font-size: 1.5em;
  }
  
  /* Set the default color of calendar dates to black */
.react-calendar__tile {
    color: black; /* Make the date text black by default */
  }
  
  /* Set the color of the calendar month and year to black */
.react-calendar__navigation button {
    color: black !important; /* Ensure the month and year are black */
  }
  
  /* Optional: Change the background color of the month navigation */
  .react-calendar__navigation {
    background-color: #f0f0f0; /* Light background for visibility */
  }
  
  /* Increase the size and set the color of navigation arrows */
  .react-calendar__navigation button:disabled {
    color: black; /* Keep navigation arrows black when disabled */
  }
  