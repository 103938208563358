.legal-ai-chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    max-width: 1100px; /* Set the maximum width for the chat container */
    margin: 0 auto; /* Center the container */
}

.chat-window {
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.chat-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.chat-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
}

.user-message {
    background-color: rgba(0, 0, 255, 0.1);
    text-align: left;
    display: inline-block; /* Change to inline-block to wrap the background around the text */
    border-radius: 10px; /* Keep the border radius for a smoother look */
    padding: 8px; /* Add some padding for better spacing */
    max-width: 80%; /* Optional: limit the width of user messages */
    margin-left: auto; /* Align to the right */
    margin-bottom: 10px; /* Space between messages */
}


.ai-message {
    text-align: left;
}

.genlaw-prefix {
    color: purple; /* Purple color for GenLaw */
    font-weight: bold;
}

.chat-input-container {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.chat-input-container textarea {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none; /* Prevent manual resizing */
    max-height: 80px; /* Maximum height for four lines (approximately) */
    overflow: hidden; /* Hide overflow to maintain the desired height */
}

.chat-input-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-input-container button:disabled {
    background-color: #cccccc;
}

.loading {
    text-align: center;
    margin-bottom: 10px;
}

.footer {
    text-align: center;
    padding: 10px;
    background-color: #f1f1f1;
    border-top: 1px solid #ddd;
}
