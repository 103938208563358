.multi-step-form-layout {
    width: 90%;
    max-width: 800px;
    padding: 30px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    margin: 40px auto; /* Add margin to center the container */
    text-align: center; /* Center the content */
  }
  
  .content-container {
    display: inline-block; /* Make the container inline-block to center it */
    text-align: left; /* Left-align the content inside the container */
    width: 100%; /* Make the container take the full width */
  }
  
  .button-container {
    display: flex; /* Make the button container a flex container */
    justify-content: space-between; /* Space the buttons evenly */
    margin-top: 20px; /* Add some margin to separate the buttons from the content */
  }

  