/* TodoPage.css */

/* Global Styling */
body, html {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f6f8;
    color: #333;
}

.todo-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.page-title {
    text-align: center;
    font-size: 2.2em;
    color: #457b9d;
    margin-bottom: 30px;
}

.add-todo-btn {
    background-color: #1d3557;
    color: #fff;
    padding: 12px 20px;
    font-size: 1em;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0 auto;
}

.add-todo-btn:hover {
    background-color: #457b9d;
}

/* To-Do and Invoice List Styling */
.todo-list, .invoice-list {
    margin-top: 40px;
}

h2 {
    font-size: 1.8em;
    color: #1d3557;
    margin-bottom: 20px;
}

/* Card Styling for To-Dos and Invoices */
.todo-card, .invoice-card {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.todo-card:hover, .invoice-card:hover {
    transform: translateY(-5px);
}

strong {
    font-size: 1.1em;
    color: #333;
}

span {
    display: block;
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
}

/* Complete Button */
.complete-btn {
    background-color: #ff6f61;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 1em;
}

.complete-btn:hover {
    background-color: #ff4e42;
}

input[type="text"],
input[type="date"],
select {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
}

.save-btn, .cancel-btn {
    padding: 12px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.save-btn {
    background-color: #1d3557;
    color: white;
}

.save-btn:hover {
    background-color: #457b9d;
}

.cancel-btn {
    background-color: #ddd;
    color: #333;
}

.cancel-btn:hover {
    background-color: #bbb;
}
