/* Review Section */
.review-section {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.review-section h3 {
  font-size: 1.8em;
  color: #1d3557;
  margin-bottom: 10px;
}

/* Basic Button Styles */
.next-button, .back-button {
  font-size: 1.1em;
  padding: 12px 30px;
  background-color: #1d3557;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Simple shadow for depth */
}

/* Remove hover effects */
.next-button:hover, .back-button:hover {
  background-color: #1d3557; /* Same background color as default */
  transform: none; /* Disable hover transformation */
}

/* Disable button when needed */
.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive styling for mobile */
@media (max-width: 768px) {
  .next-button, .back-button {
      width: 100%; /* Full width for better accessibility on mobile */
      margin-bottom: 10px; /* Space between buttons */
  }
}
