.containerDoc {
  display: flex;
  height: 100vh; /* Ensure full vertical height is used */
  margin: 0; /* Remove any default body margin */
  padding: 0;
  background-color: #f4f4f4;
}

.sidebar {
  width: 25%;
  background-color: #2c3e50;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Consistent spacing between buttons */
}

.menu-item {
  padding: 12px 15px;
  margin: 0; /* Remove additional margins */
  border-radius: 4px;
  background-color: #34495e;
  color: #ecf0f1;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #3b5998;
}

.active-menu-item {
  background-color: #4caf50;
  color: white;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; /* Ensure content starts at the top */
  padding: 10px 20px;
  overflow-y: auto;
  margin: 0; /* Remove any inherited margin */
}

.questionnaire {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Ensure consistent spacing between sections */
  width: 100%;
  margin: 0;
  padding: 0; /* Remove any default padding */
}

.placeholder {
  text-align: center;
  color: #999;
  font-size: 16px;
  margin-top: 20px; /* Adjust for consistent vertical spacing */
}

.section-container {
  margin-bottom: 20px; /* Uniform spacing between sections */
}
