.state-selection-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .state-checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .state-checkbox-container:hover {
    background-color: #f1f1f1;
  }
  
  .state-label {
    font-size: 1.1em;
    color: #333;
  }
  
  .state-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .state-checkbox:checked {
    background-color: #6200EA;
    border: none;
  }
  