/* General container styling */
.container {
    text-align: center;
    margin: auto;
    padding: 20px;
}

/* Header Styling */
header {
    background: linear-gradient(135deg, #1d3557, #457b9d);
    padding: 20px;
    color: white;
    text-align: center;
}

header h1 {
    font-size: 2.2em;
    font-weight: 700;
    margin-bottom: 5px;
}

header p {
    font-size: 1.2em;
    margin-top: 0;
    color: white;
}

/* Slideshow */
.slideshow-container {
    position: relative;
    max-width: 100%;
    margin: auto;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.slide.text p {
    font-size: 1.2rem;
    color: rgb(246, 246, 246);
}

.slide {
    display: none;
    text-align: center;
}

.slide.active {
    display: block;
}

.slide img {
    width: 100%;
    max-width: 500px;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
}

.slide-text {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px;
    border-radius: 10px;
    width: 80%;
    max-width: 300px;
    text-align: center;
    font-size: 1em;
}

.slide-text p {
    font-size: 1.2rem;
    color: rgb(253, 246, 246);
}

/* Modern Auth Buttons Container */
.auth-buttons-container {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    gap: 20px;
}

.auth-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #1d3557;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.auth-button:hover {
    background-color: #457b9d;
    transform: translateY(-5px);
}

/* Platform download buttons */
.platform-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
}

.platform-button {
    background-color: #457b9d;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.platform-button img {
    width: 150px;
    height: 50px;
    object-fit: contain;
}

.platform-button:hover {
    background-color: #1d3557;
    transform: translateY(-2px);
}

/* Section styling */
.section-dark {
    background-color: #1d3557;
    color: white;
    padding: 40px 20px;
    text-align: center;
}

.section-dark h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #a8dadc;
}

.section-dark p {
    font-size: 1.1em;
    margin-bottom: 30px;
}

/* Footer styling */
footer {
    background-color: #1d3557;
    color: white;
    padding: 20px 0;
    text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
    header h1 {
        font-size: 1.8em;
    }

    header p {
        font-size: 1em;
    }

    .auth-buttons-container {
        flex-direction: column;
    }

    .auth-button {
        width: 100%;
        margin-bottom: 10px;
    }

    .slide-text {
        width: 90%;
        max-width: 250px;
    }

    .platform-button img {
        width: 100px;
        height: 35px;
    }

    .section-dark h2 {
        font-size: 1.8em;
    }
}

/* Styling for the text above the buttons */
.auth-section-text-above {
    font-size: 2em;        /* Make the text larger */
    font-weight: bold;     /* Make it stand out */
    color: #1d3557;        /* Use a bold dark blue color to match your theme */
    margin-bottom: 20px;   /* Add some spacing below the text */
    text-align: center;    /* Center the text */
    padding: 10px 0;       /* Add some padding to give the text breathing room */
}

/* Styling for the text below the buttons */
.auth-section-text-below {
    font-size: 1.2em;      /* Slightly smaller size */
    color: #555;           /* Use a more muted gray color for the disclaimer */
    margin-top: 20px;      /* Add spacing above the text */
    text-align: center;    /* Center the text */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .auth-section-text-above {
        font-size: 1.6em;   /* Slightly smaller on mobile */
    }

    .auth-section-text-below {
        font-size: 1em;     /* Reduce size on mobile */
    }
}
