/* TopBar.css */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #6200EA;
    color: white;
    position: relative;
  }
  
  .dashboard-title {
    margin: 0;
  }
  
  .top-nav {
    display: flex;
  }
  
  .nav-link {
    margin-left: 15px;
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .nav-link:hover {
    background-color: #7e3ff2;
  }
  
  /* Hamburger menu for mobile */
  .hamburger-menu {
    cursor: pointer;
  }
  
  /* Drawer styles for mobile */
  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #6200EA;
    color: white;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .drawer-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  .drawer-nav {
    display: flex;
    flex-direction: column;
  }
  
  .drawer-link {
    margin-bottom: 20px;
    color: white;
    text-decoration: none;
    font-size: 1.2em;
    transition: color 0.3s ease;
  }
  
  .drawer-link:hover {
    color: #f0f0f0;
  }
  
  .nav-link.active {
    font-weight: bold;
    color: #ea8100; /* Change this to your preferred active color */
    border-bottom: 2px solid #ea8100; /* Optional: underline effect */
}


.top-nav {
  display: flex;
}

.nav-link {
  margin-left: 15px;
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #7e3ff2;
}