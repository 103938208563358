.dashboard-container {
  display: flex;
  flex-direction: row;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.case-list-container {
  width: 30%;
  border-right: 1px solid #ddd;
  overflow-y: auto; /* Ensure scrolling */
  padding: 10px;
  background-color: #f9f9f9;
}

.case-list-container.case-list-hidden {
  width: 0;
  border: none;
  padding: 0;
  overflow-y: hidden;
}

.case-detail-container {
  width: 70%;
  padding: 20px;
  overflow-y: auto; /* Ensure scrolling */
}

.back-button {
  display: none;
}

.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000;
  padding: 10px; /* Padding for mobile responsiveness */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px; /* Limit width on larger screens */
  max-height: 90vh; /* Ensure it doesn't overflow the screen */
  overflow-y: auto; /* Enable scrolling if content overflows */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 100%; /* Full width on mobile */
    max-width: 90vw; /* Maintain some padding on the sides */
    max-height: 90vh; /* Ensure it doesn't overflow vertically */
    padding: 15px; /* Reduce padding for mobile view */
  }

  .close-button {
    top: 5px; /* Adjust positioning for smaller screens */
    right: 5px;
    font-size: 20px; /* Smaller font size for mobile */
  }
}




/* Desktop View */
@media screen and (min-width: 769px) {
  .dashboard-container {
    flex-direction: row;
  }
  
  .case-list-container {
    display: block; /* Show case list */
  }
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  .case-list-container {
    width: 100vw;
    display: block;
  }

  .case-detail-container {
    display: none;
    max-width: 100%;
  }

  .case-detail-container.full-screen {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 1000;
    overflow-y: auto; /* Ensure scrolling */
  }
.case-card {
  padding: 15px; /* Slightly reduce padding for a cleaner look */
  margin: 8px 0; /* Less margin to keep cards close together */
  border: 1px solid #ccc; /* Softer border */
  border-radius: 8px; /* Subtle rounding */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for sleekness */
  max-width: 100vw;
}

.case-card:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Slight lift on hover */
}

.case-card.selected {
  background-color: #e0e0e0;
  border-color: #6200EA;
}

  
.back-button {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1001;
  max-width: calc(100% - 20px);
}
}
