/* Ensure full-page scroll, no internal scrolls */
body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto; /* Main scroll */
  }
  
  .multi-step-form-layout {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: visible; /* Allow the entire page to scroll */
  }
  
  .district-selection-page {
    padding: 20px;
    overflow: visible; /* Ensure no internal scrolls */
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  
  .district-checkbox-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .state-heading {
    font-size: 1.5em;
    margin: 20px 0;
  }
  
  .district-checkbox {
    margin-left: 10px;
  }
  
  /* General layout improvements */
  .content-container {
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    background-color: #f9f9f9;
    overflow: visible; /* No scroll here */
  }
  
  .back-button, .next-button {
    padding: 12px 24px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
  }
  
  .back-button {
    background-color: #ff6f61;
    color: white;
  }
  
  .next-button {
    background-color: #457b9d;
    color: white;
  }
  