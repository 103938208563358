/* General Styles */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
  }
  
  /* Team Container */
  .team-container {
    padding: 30px;
    text-align: center;
    background-color: #fff;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding-bottom: 50px; /* Adds space at the bottom */
    margin-bottom: 15px; /* Adds space between team member cards */


  }
  
  /* Team Heading */
  .team-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Team Member Card */
  .team-member-card {
    background-color: #ffffff;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: left;
    transition: box-shadow 0.3s ease;
  }
  
  .team-member-card:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Team Member Info */
  .team-member-card strong {
    font-size: 1.1rem;
    color: #6200ea;
  }
  
  /* Add Member Form */
  .add-member-form input {
    padding: 10px;
    margin: 10px;
    width: 220px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 1rem;
  }
  
  .add-member-form button {
    padding: 10px 20px;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .add-member-form button:hover {
    background-color: #4a00c8;
  }

  .lottie-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust height as needed */
    margin-bottom: 20px; /* Add some spacing after the animation */
  }
  